/* globals WEB_DOMAIN */

import axios from 'axios'

export const WEB_PROTOCOL = WEB_DOMAIN.startsWith("localhost") ? "http": "https"

const instance = axios.create({
  baseURL: `${WEB_PROTOCOL}://${WEB_DOMAIN}/`,
})

if(window.location.pathname === '/login') {
  const token = localStorage.getItem('token')
  if(token !== null) {
    localStorage.clear()
  }
}

const defaultConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
}

const getConfig = (auth) => {
  if (!auth) {
    return defaultConfig
  }

  return {
    ...defaultConfig,
    headers: {
      ...defaultConfig.headers,
      Authorization: localStorage.getItem('token'),
    },
  }
}

const onSuccess = res => ({ data: res.data, headers: res.headers, status: res.status })
const onError = err => ({ data: null, headers: err.headers, error: { status: err.status }})

export const get = (url, auth, params) =>
  instance.get(url, { ...getConfig(auth), params }).then(onSuccess).catch(onError)

export const post = (url, data, auth) =>
  instance.post(url, data, getConfig(auth)).then(onSuccess).catch(onError)

export const put = (url, data, auth) =>
  instance.put(url, data, getConfig(auth)).then(onSuccess).catch(onError)

export const remove = (url, auth) =>
  instance.delete(url, getConfig(auth)).then(onSuccess).catch(onError)

export const del = (url, auth) => { return instance.delete(url, getConfig(auth)) }

export const file = (url) =>
  instance.get(url, {
    headers: {
      'Accept': 'application/octet-stream',
      'Content-Type': 'application/octet-stream',
      'Authorization': localStorage.getItem('token'),
    },
    responseType: 'blob',
  })
