import { createContext, useContext, useState } from 'react'

const Visitor = createContext()

/**
 * @param { import('react').PropsWithChildren<{}> } props
 */
export function VisitorProvider({initialUser, children }) {
    const state = useState(initialUser)
    return <Visitor.Provider value={state}>{children}</Visitor.Provider>
}

/**
 * Visitor hook
 * 
 * @returns {[
 *  import('../api').SecureUser, 
 *  import('react').Dispatch<import('react').SetStateAction<object>>
 * ]} visitor hook
 */
export function useVisitor() {
    const visitor = useContext(Visitor)
    if (visitor === undefined) {
        throw new Error('useVisitor must be used within a VisitorProvider')
    }
    return visitor
}
