import { lazy } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { VisitorProvider } from './hooks/use-visitor'
import jwtDecode from 'jwt-decode'
import { getDoctorsList } from './services/http-service/api'


const App = lazy(() => import('./App'))

function ready(fn) {
    if (document.readyState != 'loading') {
        fn()
    } else {
        document.addEventListener('DOMContentLoaded', fn)
    }
}

ready(async () => {
    const root = createRoot(document.getElementById('root'))
    // "Bearer XXXXX"
    const storedToken = localStorage.getItem('token')
    const tokenData = storedToken && storedToken.split(" ", 2)
    let auth
    if (tokenData && tokenData.length == 2) {
        const token = jwtDecode(tokenData[1])
        if (token.role === 2) {
            // doctor login
            const response = await getDoctorsList()
            if (response.status == 200) {
                const user = response.data.find(user => user.userId == token.uid)
                auth = { ...user, token: tokenData[1], isAdmin: false }
            }
        } else {
            // root login
            auth = { ...token, token: tokenData[1], id: token.uid, isAdmin: token.role === 0 }
        }
    }
    root.render((
        <VisitorProvider initialUser={auth}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </VisitorProvider>
    ))
})
